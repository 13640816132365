// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-power-plant-details-js": () => import("./../../../src/components/power-plant-details.js" /* webpackChunkName: "component---src-components-power-plant-details-js" */),
  "component---src-components-state-details-js": () => import("./../../../src/components/state-details.js" /* webpackChunkName: "component---src-components-state-details-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-500-js": () => import("./../../../src/pages/500.js" /* webpackChunkName: "component---src-pages-500-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-power-plant-social-card-js": () => import("./../../../src/pages/power-plant-social-card.js" /* webpackChunkName: "component---src-pages-power-plant-social-card-js" */),
  "component---src-pages-social-card-js": () => import("./../../../src/pages/social-card.js" /* webpackChunkName: "component---src-pages-social-card-js" */),
  "component---src-pages-take-action-js": () => import("./../../../src/pages/take-action.js" /* webpackChunkName: "component---src-pages-take-action-js" */),
  "component---src-pages-terminology-js": () => import("./../../../src/pages/terminology.js" /* webpackChunkName: "component---src-pages-terminology-js" */)
}

